import * as React from "react";
import {useForm} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import sfpro from '@/theme/localFont';
import IconFlaticon from "@/components/IconFlaticon";
import axios from "axios";
import Image from "next/image";
import iconSent from '../../../public/assets/clapps/images/icon-paper-plane.svg';

const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto'
}

const StyledInput = styled(TextField) (({theme}) => ({
    marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
        marginBottom: '1.25rem',
    },
    '& label': {
        color: '#FFFFFF',
        fontSize: '16px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
    },
    '& label.Mui-focused': {
        color: '#FF5330',
    },
    '& .MuiInput-input' : {
        color: '#FFFFFF',
        fontSize: '16px',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
    },
    '& .MuiInput-underline:before': {
        borderWidth: '2px',
        borderBottomColor: '#FFFFFF',
    },
    '&:hover .MuiInput-underline:before': {
        borderBottomColor: '#FFFFFF!important',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#FF5330',
    },
    '& textarea':{
        paddingTop: '2px',
        paddingBottom: '1px'
    }
}))

function GridElementModal(props){
    return(
        <>
            <div className={'lan2_li_modal'}>
                <h2 className={'lan2_li_modal_h2'}>
                    {props.title}
                </h2>
                <p className={'lan2_li_modal_text'}>
                    {props.text}
                </p>
                <Button className={'lan2_li_modal_button'} onClick={props.handleOpenModal}>
                    Заказать обратный звонок
                </Button>
            </div>
        </>
    )
}

function FeedbackModal(props){
    const { getValues, control, reset, register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
    const [loading, setLoading] = React.useState(false);
    //inputs
    const [inputTextLength, setInputTextLength] = React.useState({
        name: 0,
        phone: 0,
    })
    const handleInputTextLength = e => {
        const {name, value} = e.target;
        setInputTextLength({...inputTextLength,[name]:value.length});
    }

    const onError = (errors, e) => {
        // console.log(errors, e);
        clearErrors()
    }

    async function onSubmit(data){
        let validEmail = !data.email || /.+@.+\.[A-Za-z]+$/.test(data.email);
        let validPhone = data.phone && data.phone.replace(/[^0-9]/g,'').length === 11;
        let validName = data.name && data.name.trim().length > 2;
        setLoading(true)
        data.sendTo = 'mail@clapps.ru'//mail@clapps.ru
        data.subject = 'Заказ на разработку'

        if(!validEmail){
            setError('email', { type: 'custom', message: 'Email не корректен!' })
        }

        if(!validPhone){
            setError('phone', { type: 'custom', message: 'Телефон не указан!' })
        }

        if(!validName){
            setError('name', { type: 'custom', message: 'Имя не указано!' })
        }

        if(validEmail && validPhone && validName){
            const text = `Заявка на разработку (clapps.ru)`
            const html = `<h1>Заявка на разработку!</h1>
            <p>Имя: ${data.name}<p>
            <p>Email: ${data.email}<p>
            <p>Телефон: ${data.phone}<p>
            <p>О проекте: ${data.comment}<p>`

            const response = await axios.post(`/api/sendmail`, { email: data.sendTo, subject: data.subject, text, html})
            if(response.data.error){
                setError('all', { type: 'custom', message: response.data.error })
            }
            if(response.data.data){
                props.onSent();
                reset()
            }
        }
        setLoading(false);
    }

    return(
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.isOpen}
            onClose={props.onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    timeout: 500
                },
            }}
        >
            <Fade in={props.isOpen}>
                <Box sx={{ ...modalBoxStyle}} className={`relative pt-6 sm:pt-0 max-w-4xl bg-black-2 border-0 outline-0 ${sfpro.className}`}>
                    <div className="absolute flex flex-col h-6 w-6 right-3.5 top-3.5 items-center justify-center bg-black-3 hover:bg-red-1 cursor-pointer" onClick={props.onClose}>
                        <IconFlaticon name='br-cross-small' className='flex text-black-2 text-2xl' />
                    </div>
                    {props.isSent === 1 ? (
                        <>
                            <div className={'flex flex-col px-10 sm:px-0 mt-12 sm:mt-24 items-center justify-center'}>
                                <Image
                                    src={iconSent}
                                    alt="ICON"
                                    quality={100}
                                    sizes="100vw"
                                    style={{
                                        width: 'auto',
                                        height: 'auto',
                                    }}
                                />
                                <div className={'mt-16 text-4xl text-white-3 font-black'}>
                                    Заявка отправлена
                                </div>
                                <div className={'mt-5 mb-20 text-22px text-white-3 font-normal'}>
                                    Скоро мы свяжемся с вами
                                </div>
                            </div>
                            <div className={'flex flex-col w-full items-center bg-black-3'}>
                                <Button className={'max-w-72 w-full h-12 sm:h-16 mt-4 sm:mt-10 btn-color-filled-alt'} onClick={props.onClose}>
                                    Отлично!
                                </Button>
                                <div className={'pt-2 sm:pt-6 pb-4 sm:pb-10 text-8px sm:text-xs text-center text-gray-1 leading-normal'}>
                                    Нажимая кнопку «Отправить заявку» я соглашаюсь с политикой <br/> конфиденциальности и обработки персональных данных
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={'px-10 sm:px-0 mt-4 sm:mt-18 mb-2 sm:mb-12 text-xl md:text-4xl text-white text-center font-black'}>
                                Давайте обсудим ваш проект
                            </div>
                            <form noValidate onSubmit={handleSubmit(onSubmit, onError)} className={'flex flex-col max-w-300 w-full justify-center items-center'}>
                                <div className={'px-10 xl:px-32 mb-5'}>
                                    <StyledInput
                                        fullWidth
                                        {...register('name')}
                                        error={!!errors.name}
                                        name={'name'}
                                        label={'Ваше имя'}
                                        variant={'standard'}
                                        required={true}
                                        onInput={handleInputTextLength}
                                    />
                                    <StyledInput
                                        fullWidth
                                        {...register('phone')}
                                        error={!!errors.phone}
                                        name={'phone'}
                                        label={'Номер телефона'}
                                        variant={'standard'}
                                        required={true}
                                        onInput={handleInputTextLength}
                                    />
                                    <StyledInput
                                        fullWidth
                                        {...register('email')}
                                        error={!!errors.email}
                                        name={'email'}
                                        label={'E-mail'}
                                        variant={'standard'}
                                    />
                                    <StyledInput
                                        fullWidth
                                        {...register('comment')}
                                        error={!!errors.comment}
                                        name={'comment'}
                                        label={'Расскажите о вашем проекте'}
                                        variant={'standard'}
                                        multiline={true}
                                        maxRows={5}
                                    />
                                </div>
                                <div className={'flex flex-col w-full items-center bg-black-3'}>
                                    <LoadingButton
                                        type={'submit'}
                                        loading={loading}
                                        disabled={inputTextLength.name < 2 || inputTextLength.phone < 5}
                                        className={'max-w-72 w-full h-12 sm:h-16 mt-4 sm:mt-10 btn-color-filled-alt'}
                                    >
                                        Отправить заявку
                                    </LoadingButton>
                                    {errors.all && ( `${errors.all.message}` )}
                                    <div className={'pt-2 sm:pt-6 pb-4 sm:pb-10 text-8px sm:text-xs text-center text-gray-1 leading-normal'}>
                                        Нажимая кнопку «Отправить заявку» я соглашаюсь с политикой <br/> конфиденциальности и обработки персональных данных
                                    </div>
                                </div>
                            </form>
                        </>
                    )}
                </Box>
            </Fade>
        </Modal>
    )
}

export {FeedbackModal, GridElementModal}