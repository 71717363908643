import NextLink from "next/link";
import Image from "next/image";
import telegramIcon from '../../../public/assets/clapps/images/icon-telegram.svg';
import whatsappIcon from '../../../public/assets/clapps/images/icon-whatsapp.svg';
import * as React from "react";

export default function Footer(props){
    return(
        <footer itemScope itemType="http://schema.org/WPFooter" className="footer">
            <div className="footer_block">
                <nav itemScope itemType="http://schema.org/SiteNavigationElement">
                    <ul>
                        <li className="footer_nav">
                            <NextLink itemProp="url" href={'/#products'} className="footer_nav_div">
                                Разработки
                            </NextLink>
                            <ul className="footer_nav_ul">
                                <li>
                                    <NextLink itemProp="url" href={'/lp-shop'} className={'footer_nav_ul_li'}>
                                        Интернет-магазин
                                    </NextLink>
                                </li>
                                <li>
                                    <NextLink itemProp="url" href={'/lp-app'} className={'footer_nav_ul_li'}>
                                        Мобильные приложения
                                    </NextLink>
                                </li>
                                <li>
                                    <NextLink itemProp="url" href={'/lp-business'} className={'footer_nav_ul_li'}>
                                        Бизнес-сайты
                                    </NextLink>
                                </li>
                                <li>
                                    <NextLink itemProp="url" href={'/lp-crm'} className={'footer_nav_ul_li'}>
                                        CRM-системы
                                    </NextLink>
                                </li>
                                <li>
                                    <NextLink itemProp="url" href={'/lp-landing'} className={'footer_nav_ul_li'}>
                                        Landing page
                                    </NextLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <div className="flex flex-col space-y-8">
                    <nav itemScope itemType="http://schema.org/SiteNavigationElement">
                        <ul>
                            <li className="footer_nav">
                                <NextLink itemProp="url" href={'/#solutions'} className="text-white hover:text-red-1 text-sm sm:text-lg leading-none">
                                    Готовые решения
                                </NextLink>
                                <ul className="flex flex-col space-y-3">
                                    <li className={'text-sm sm:text-base'}>Интернет-витрина</li>
                                    <li>
                                        <NextLink itemProp="url" href={'/lp-buy'} className={'footer_nav_ul_li'}>
                                            Интернет-магазин
                                        </NextLink>
                                    </li>
                                    <li className={'text-sm sm:text-base'}>Web-сервис 1С (УНФ)</li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <nav itemScope itemType="http://schema.org/SiteNavigationElement">
                        <ul>
                            <li className="footer_nav">
                                <NextLink itemProp="url" href={'/#portfolio'} className="text-white hover:text-red-1 text-sm sm:text-lg leading-none">Портфолио</NextLink>
                                <ul className="flex flex-col space-y-3">
                                    <li>
                                        <NextLink href={'https://ecommy.ru'} target={'_blank'} className={'footer_nav_ul_li'}>Ecommy</NextLink>
                                    </li>
                                    <li>
                                        <NextLink href={'https://яумею.com'} target={'_blank'} className={'footer_nav_ul_li'}>Я умею</NextLink>
                                    </li>
                                    <li>
                                        <NextLink href={'https://бизнес.яумею.com'} target={'_blank'} className={'footer_nav_ul_li'}>Я умею.Бизнес</NextLink>
                                    </li>
                                    <li>
                                        <NextLink href={'https://d-tech.pro'} target={'_blank'} className={'footer_nav_ul_li'}>Ди-Тек</NextLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="footer_nav">
                    <nav itemScope itemType="http://schema.org/SiteNavigationElement">
                        <ul>
                            <li className="footer_nav">
                                <NextLink itemProp="url" href={'/contacts'} className="text-white hover:text-red-1 text-sm sm:text-lg leading-none">Контакты</NextLink>
                            </li>
                        </ul>
                    </nav>
                    <div itemScope itemType="http://schema.org/Organization" className="flex flex-col space-y-3">
                        <div className="flex flex-row text-sm sm:text-base">
                            <span itemProp="name">Clapps</span>
                            <span>,  &nbsp;</span>
                            <span itemProp="description">it-студия</span>
                        </div>
                        <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress" className="flex flex-row text-sm sm:text-base">
                            <span itemProp="addressCountry">Россия</span>
                            <span>,  &nbsp;</span>
                            <span itemProp="addressLocality">Иркутск</span>
                        </div>
                        <NextLink className="footer_nav_ul_li" href="tel:89246053995"><span itemProp="telephone">+7(924)605-39-95</span></NextLink>
                        <NextLink className="footer_nav_ul_li" href="mailto:mail@clapps.ru"><span itemProp="email">mail@clapps.ru</span></NextLink>
                        <NextLink className="flex items-center pt-1 footer_nav_ul_li" href="https://t.me/+79246053995">
                            <Image
                                src={telegramIcon}
                                alt="IMAGE"
                                quality={100}
                                // sizes="100vw"
                                style={{
                                    width: 'auto',
                                    height: 'auto',
                                    marginRight: '0.5rem'
                                }}
                            />
                            Telegram
                        </NextLink>
                        <NextLink className="flex items-center pt-1 text-sm sm:text-base text-white-3 hover:text-red-1" href="https://wa.clck.bar/79246053995">
                            <Image
                                src={whatsappIcon}
                                alt="IMAGE"
                                quality={100}
                                // sizes="100vw"
                                style={{
                                    width: 'auto',
                                    height: 'auto',
                                    marginRight: '0.5rem'
                                }}
                            />
                            WhatsApp
                        </NextLink>
                        <NextLink className="text-sm sm:text-base text-white hover:text-red-1" itemProp="url" href="https://clapps.ru">clapps.ru</NextLink>
                    </div>
                    <div>2024</div>
                    <a href="https://webmaster.yandex.ru/siteinfo/?site=https://clapps.ru">
                        <img width="88" height="31" alt="" border="0" src="https://yandex.ru/cycounter?https://clapps.ru&theme=light&lang=ru"/>
                    </a>
                </div>
            </div>
        </footer>
    )
}